import React, { ReactNode, useRef } from 'react'
import { styled } from '@mui/material'
import {
    Widget,
    FileUpload,
    FileInfo,
    WidgetAPI,
    WidgetProps,
} from '@uploadcare/react-widget'
import { useGetCustomSignedUrl } from '../../services/signing'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'

import { toast } from 'react-toastify'
/*
 * UploadWrapper - wraps children components to make a clickable region which is used for uploading files.
 * If the desired behavior is to simply have a button usable for uploading files, simply use the Widget component
 * directly from uploadcare.
 */

interface UploadWrapperProps {
    onFileSelect: (fileInfo: FileUpload | null) => void
    children?: ReactNode
    hideOverlay?: boolean
    validators?: ((fileInfo: FileInfo) => void)[]
}

const CenterDiv = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
})

export const fileTypeLimit = (allowedFileTypes: string) => {
    const types = allowedFileTypes.split(' ')

    return function (fileInfo: FileInfo) {
        if (fileInfo.name === null) {
            return
        }
        const extension = fileInfo.name.split('.').pop()

        if (extension && !types.includes(extension)) {
            toast.error('Upload must be a pdf.')
            throw new Error('fileType')
        }
    }
}

// cannot pass ref to FC directly
export type UploadWidgetProps = Exclude<
    WidgetProps,
    | 'publicKey'
    | 'previewStep'
    | 'crop'
    | 'previewUrlCallback'
    | 'cdnBase'
    | 'ref'
> & {
    widgetApi?: React.RefObject<WidgetAPI | undefined>
}

export const UploadWidget: React.FC<UploadWidgetProps> = (props) => {
    const { signedUrl } = useGetCustomSignedUrl(props.value)

    const { value, widgetApi, ...rest } = props

    return (
        <Widget
            {...rest}
            publicKey={process.env.NEXT_PUBLIC_UPLOADCARE_PUBLIC_KEY ?? ''}
            tabs={'file'}
            previewStep
            // @ts-ignore
            crop
            cdnBase={process.env.NEXT_PUBLIC_UPLOADCARE_CDN_BASE}
            previewUrlCallback={() => signedUrl ?? ''}
            // Widget only resets with null (and their docs reference it) but causes type error.
            // @ts-ignore
            value={value === undefined ? null : value}
            // @ts-ignore
            ref={widgetApi}
        />
    )
}

export const UploadWrapper = ({
    onFileSelect,
    children,
    hideOverlay,
    validators = undefined,
}: UploadWrapperProps) => {
    const widgetApi = useRef<WidgetAPI>()

    return (
        <div
            onClick={() => widgetApi?.current?.openDialog()}
            className="container"
        >
            {!hideOverlay ? (
                <div className="overlay">
                    <CenterDiv>
                        <PhotoCameraIcon
                            style={{ zIndex: 40, position: 'absolute' }}
                        />
                    </CenterDiv>
                </div>
            ) : null}
            {children}
            <div style={{ display: 'none' }}>
                <UploadWidget
                    widgetApi={widgetApi}
                    style={{ zIndex: 10, display: 'none' }}
                    // @ts-ignore
                    onFileSelect={onFileSelect}
                    validators={validators}
                />
            </div>
            <style>{`
                .container {
                    cursor: pointer;
                    position: relative;
                }
                .overlay {
                    position: absolute;
                    opacity: 0;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 100%;
                    width: 100%;
                }
                .container:hover .overlay {
                    opacity: 1;
                }
            `}</style>
        </div>
    )
}
