import { Alert, Button, Stack } from '@mui/material'
import { type FC, useCallback } from 'react'
import { useFirmVisitationState } from './firmVisitationState'
type FirmVisitationBannerProps = {}

export const FirmVisitationBanner: FC<FirmVisitationBannerProps> = ({}) => {
    const handleVisitStateChange = useCallback(
        () => window.location.replace('/'),
        []
    )
    const { visitId, setVisitId } = useFirmVisitationState(
        handleVisitStateChange
    )

    if (!visitId) {
        return <></>
    }
    return (
        <Stack direction="row" spacing={0.5}>
            <Alert severity="warning">Firm visitation active</Alert>
            <Button onClick={() => setVisitId(null)}>
                Return to home firm
            </Button>
        </Stack>
    )
}
