import { styled } from '@mui/material'

export const FullWidthFlexHeightBox = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '0',
    flexShrink: 1,
    flexGrow: 1,
})
