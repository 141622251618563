import React, { useState } from 'react'
import { styled } from '@mui/material'
import { IconButton, Stack, CircularProgress } from '@mui/material'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Image from 'next/legacy/image'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import { stringAvatar } from '../../utils/avatar'
import { FileUpload } from '@uploadcare/react-widget'
import { updateOfficerImage } from '../../services/officer'
import { useGetSignedUrl } from '../../services/signing'
import { useSWRConfig } from 'swr'
import { UploadWrapper } from '../UploadWrapper'
import LaunchIcon from '@mui/icons-material/Launch'
import { useRouter } from 'next/router'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { toast } from 'react-toastify'

const OfficerNameContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'left',
    height: '100%',
    cursor: 'pointer',
})

const defaultAvatarSize = 32

interface OfficerLabelProps {
    userImageUrl?: string
    avatarSize?: number
    styledName?: JSX.Element
    name: string
    children?: JSX.Element
    enableImageUpload?: boolean
    officerId?: string
    linkToPage?: boolean
    phoneNumber?: string
    withOfficerName?: boolean
    onLocationIconClick?: () => void
    lowerImageQuality?: boolean
}

type SignedUserImageType = {
    userImageUrl?: string
    avatarSize?: number
    name: string
}
type UnsignedUserImageType = {
    userImageUrl?: string
    avatarSize?: number
    name: string
    lowerImageQuality?: boolean
}

export const SignedUserImage = ({
    userImageUrl,
    avatarSize,
    name,
}: SignedUserImageType) => {
    return userImageUrl != undefined ? (
        <Avatar
            key={userImageUrl}
            sx={
                avatarSize
                    ? { width: avatarSize, height: avatarSize }
                    : {
                          width: defaultAvatarSize,
                          height: defaultAvatarSize,
                      }
            }
        >
            <Image src={userImageUrl} layout="fill" alt="User Image" />
        </Avatar>
    ) : (
        <Avatar
            {...stringAvatar(name, avatarSize ? avatarSize : defaultAvatarSize)}
        />
    )
}
export const UnsignedUserImage = ({
    userImageUrl,
    avatarSize,
    name,
    lowerImageQuality,
}: UnsignedUserImageType) => {
    const { signedUrl } = useGetSignedUrl(userImageUrl, lowerImageQuality)
    return signedUrl != undefined ? (
        <Avatar
            key={userImageUrl}
            sx={
                avatarSize
                    ? { width: avatarSize, height: avatarSize }
                    : {
                          width: defaultAvatarSize,
                          height: defaultAvatarSize,
                      }
            }
        >
            {/* TODO(rdylan): Follow up with a loader file for different sizes */}
            <Image
                src={signedUrl}
                width={avatarSize ?? defaultAvatarSize}
                height={avatarSize ?? defaultAvatarSize}
                alt="User Image"
                unoptimized
            />
        </Avatar>
    ) : (
        <Avatar
            {...stringAvatar(name, avatarSize ? avatarSize : defaultAvatarSize)}
        />
    )
}

export const SignedOfficerLabel = ({
    userImageUrl,
    avatarSize,
    styledName,
    name,
    children,
    enableImageUpload,
    officerId,
    linkToPage,
    phoneNumber,
    onLocationIconClick,
}: OfficerLabelProps) => {
    const router = useRouter()
    const [_loading, setLoading] = useState(false)
    const { mutate } = useSWRConfig()

    const userImage = (
        <SignedUserImage
            userImageUrl={userImageUrl}
            avatarSize={avatarSize}
            name={name}
        />
    )

    const onFileSelect = async (file: FileUpload | null) => {
        if (file && officerId) {
            const fileInfo = await file.promise()
            if (fileInfo.isImage && fileInfo.size) {
                file.done(async (info) => {
                    setLoading(true)
                    await updateOfficerImage(
                        officerId,
                        info.cdnUrl ?? undefined,
                        mutate
                    )
                    setLoading(false)
                })
            } else {
                toast.error('Please upload a valid image')
            }
        }
    }

    const officerName = styledName ? (
        styledName
    ) : (
        <Typography fontSize={18} fontWeight={500} component="div">
            {name ?? ''}
        </Typography>
    )

    const onOfficerProfileLinkClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        if (officerId) {
            window.open(
                `${router.basePath}/employees/${officerId}/details`,
                '_blank',
                'noreferrer'
            )
        }
    }

    const OfficerNameLabel = () => {
        if (linkToPage && officerId) {
            return (
                <Stack direction="row" alignItems="center">
                    <Tooltip arrow title={phoneNumber ?? ''} placement="top">
                        {officerName}
                    </Tooltip>
                    {onLocationIconClick ? (
                        <IconButton
                            color={'primary'}
                            onClick={() => onLocationIconClick()}
                            sx={{ ml: -1.5 }}
                        >
                            <LocationOnIcon />
                        </IconButton>
                    ) : null}
                </Stack>
            )
        } else {
            return <OfficerNameContainer>{officerName}</OfficerNameContainer>
        }
    }

    return (
        <Grid container spacing={1} wrap="nowrap" alignItems="center">
            <Grid item onClick={onOfficerProfileLinkClick}>
                <OfficerNameContainer>
                    {enableImageUpload && (
                        <UploadWrapper onFileSelect={onFileSelect}>
                            {userImage}
                        </UploadWrapper>
                    )}
                    {!enableImageUpload && userImage}
                </OfficerNameContainer>
            </Grid>
            <Grid
                item
                sx={{
                    overflow: 'hidden',
                }}
                onClick={onOfficerProfileLinkClick}
            >
                <OfficerNameLabel />
            </Grid>
            {children}
        </Grid>
    )
}

export const OfficerLabel = ({
    userImageUrl,
    avatarSize,
    styledName,
    name,
    children,
    enableImageUpload,
    officerId,
    linkToPage,
    phoneNumber,
    withOfficerName = true,
    lowerImageQuality = false,
}: OfficerLabelProps) => {
    const router = useRouter()
    const [loading, setLoading] = useState(false)
    const { mutate } = useSWRConfig()
    const userImage = userImageUrl?.includes('token') ? (
        <SignedUserImage
            userImageUrl={userImageUrl}
            avatarSize={avatarSize}
            name={name}
        />
    ) : (
        <UnsignedUserImage
            userImageUrl={userImageUrl}
            avatarSize={avatarSize}
            name={name}
            lowerImageQuality={lowerImageQuality}
        />
    )

    const onFileSelect = async (file: FileUpload | null) => {
        if (file && officerId) {
            const fileInfo = await file.promise()
            if (fileInfo.isImage && fileInfo.size) {
                file.done(async (info) => {
                    setLoading(true)
                    await updateOfficerImage(
                        officerId,
                        info.cdnUrl ?? undefined,
                        mutate
                    )
                    setLoading(false)
                })
            } else {
                toast.error('Please upload a valid image')
            }
        }
    }

    const officerName = styledName ? styledName : name ? name : ''

    const onOfficerProfileLinkClick = (e: React.MouseEvent) => {
        e.stopPropagation()
        if (officerId) {
            window.open(
                `${router.basePath}/employees/${officerId}/details`,
                '_blank',
                'noreferrer'
            )
        }
    }

    const OfficerNameLabel = () => {
        if (linkToPage && officerId) {
            return (
                <Stack direction="row" alignItems="center">
                    <Tooltip arrow title={phoneNumber ?? ''} placement="top">
                        <Typography
                            fontSize={18}
                            fontWeight={500}
                            component="div"
                        >
                            {officerName}
                        </Typography>
                    </Tooltip>
                    <IconButton
                        onClick={onOfficerProfileLinkClick}
                        sx={{ mr: 0.25 }}
                    >
                        <LaunchIcon fontSize="small" />
                    </IconButton>
                </Stack>
            )
        } else {
            return <OfficerNameContainer>{officerName}</OfficerNameContainer>
        }
    }

    return (
        <Grid container spacing={2} wrap="nowrap">
            <Grid item>
                <OfficerNameContainer>
                    {loading ? (
                        <CircularProgress size={24} />
                    ) : (
                        <>
                            {enableImageUpload && (
                                <UploadWrapper onFileSelect={onFileSelect}>
                                    {userImage}
                                </UploadWrapper>
                            )}
                            {!enableImageUpload && userImage}
                        </>
                    )}
                </OfficerNameContainer>
            </Grid>
            {withOfficerName && (
                <Grid item sx={{ overflow: 'hidden' }}>
                    <OfficerNameLabel />
                </Grid>
            )}
            {children}
        </Grid>
    )
}
