type GenericResponse = {
    data: any
} & BlobPart
type FileResponseHandlerParams = {
    fileName: string
    mimeType?: string
}
/*
 *
 * Usage: Chain this off of a Promise of a file download to cause the download to open in a new tab when complete
 * Example: Service.getMyFileFromApiV1().then(handleFileResponse({fileName, mimeType}))
 *
 */
export function handleFileResponse({
    fileName,
    mimeType,
}: FileResponseHandlerParams) {
    return function (response: GenericResponse) {
        const link = document.createElement('a')
        link.href = URL.createObjectURL(
            new Blob([response], mimeType ? { type: mimeType } : {})
        )
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        return response.data
    }
}
