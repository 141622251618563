import React from 'react'
import { DetailTabs } from '../DetailTabs'
import { DetailsTab } from '../../types/detailsTab'
import { withRole } from '../withRole'
import { FullWidthFlexHeightBox } from 'src/shared/styles/theme/styled/FullWidthFlexHeightBox'

type LayoutProps = React.PropsWithChildren<{}>

const TabbedCustomerParentsLayoutAdmin = ({ children }: LayoutProps) => {
    const tabLinks: DetailsTab[] = [
        {
            name: 'Sites',
            link: `/customers/sites`,
            linkRoot: `/customers/sites`,
        },
        {
            name: 'Parents',
            link: `/customers/parents`,
            linkRoot: `/customers/parents`,
        },
    ]

    return (
        <FullWidthFlexHeightBox>
            <DetailTabs data={tabLinks} />
            {children}
        </FullWidthFlexHeightBox>
    )
}

const TabbedCustomerParentsLayoutBasic = ({ children }: LayoutProps) => {
    return <FullWidthFlexHeightBox>{children}</FullWidthFlexHeightBox>
}

export const TabbedCustomerParentsLayout = withRole(['Admin'])(
    TabbedCustomerParentsLayoutAdmin,
    TabbedCustomerParentsLayoutBasic
)
