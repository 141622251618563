import React from 'react'
import { InnerTabs } from '../InnerTabs/InnerTabs'
import { DetailsTab } from '../../types/detailsTab'

type LayoutProps = React.PropsWithChildren<{}>
export function TabbedReportsBelltowerLayout({ children }: LayoutProps) {
    const linksData: DetailsTab[] = [
        {
            name: 'Reports',
            link: `/belltower/reports`,
            linkRoot: `/belltower/reports/reports`,
        },
        {
            name: 'Report Templates',
            link: `/belltower/report-templates`,
            linkRoot: `/belltower/reports/report-templates`,
            hideOnMobile: true,
        },
        {
            name: 'Lists',
            link: `/belltower/belfrylists`,
            linkRoot: `/belltower/reports/lists`,
        },
    ]
    return (
        <>
            <InnerTabs data={linksData} />
            {children}
        </>
    )
}
