import React from 'react'
import {
    styled,
    Button,
    Grid,
    Typography,
    ButtonGroup,
    TextField,
} from '@mui/material'
import { Loading } from '../Loading'
import { useSWRConfig } from 'swr'
import { ApiError } from '../../generated'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { ContentModal } from '../Modal'
import { useUserIsInternal } from '../../utils/auth'
import { ReactNode } from 'react'

type EntityType = 'customer' | 'officer'

type DeleteModalProps = {
    open: boolean
    handleClose: () => void
    entityName: string | undefined
    entityId: string
    entityType: EntityType
    deleteEntity: (id: string, mutate: any) => Promise<any>
    caption: ReactNode
}

const DeleteModal = ({
    open,
    handleClose,
    entityName,
    entityId,
    entityType,
    deleteEntity,
    caption,
}: DeleteModalProps) => {
    const { mutate } = useSWRConfig()
    const router = useRouter()
    const [deleteEntityName, setDeleteEntityName] = React.useState<string>('')
    const [submitting, setSubmitting] = React.useState<boolean>(false)
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null)

    const deleteEntityCallback = React.useCallback(() => {
        setSubmitting(true)
        setErrorMessage(null)
        return deleteEntity(entityId, mutate)
            .then(() => {
                setSubmitting(false)
                handleClose()
                router.push(
                    `/${entityType === 'officer' ? 'employees' : 'customers'}`
                )
            })
            .catch((error: ApiError) => {
                const message =
                    error?.body?.detail || error?.message || 'An error occurred'
                setErrorMessage(message)
                toast.error(error?.message)
                setSubmitting(false)
            })
    }, [entityId, entityType, deleteEntity, mutate, handleClose, router])

    const entityTypeDisplay = entityType === 'officer' ? 'Worker' : 'Customer'

    return (
        <ContentModal isOpen={open} handleClose={handleClose}>
            {submitting && <Loading style={{ minHeight: undefined }} />}
            {!submitting && (
                <Grid container direction="column" spacing={3}>
                    <Grid item>
                        <Typography variant="h4">
                            Delete {entityTypeDisplay}
                        </Typography>
                        <Typography variant="body1">
                            This will delete{' '}
                            {entityType === 'officer'
                                ? 'this worker'
                                : entityName}{' '}
                            and all associated data.
                        </Typography>
                        {typeof caption === 'string' ? (
                            <Typography variant="body1" paragraph>
                                {caption}
                            </Typography>
                        ) : (
                            <>{caption}</>
                        )}
                        <Typography variant="body1">
                            <strong>
                                No record of this{' '}
                                {entityType === 'officer'
                                    ? 'worker'
                                    : 'customer'}{' '}
                                will remain and this action cannot be undone.
                            </strong>
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            Please enter <b>{entityName}</b> to confirm
                            deletion:
                        </Typography>
                        <TextField
                            value={deleteEntityName}
                            onChange={(e) =>
                                setDeleteEntityName(e.target.value)
                            }
                            fullWidth
                        />
                    </Grid>
                    <Grid item marginTop={3}>
                        {errorMessage && (
                            <Typography color="error">
                                {errorMessage}
                            </Typography>
                        )}
                        <ButtonGroup fullWidth>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button
                                variant="contained"
                                color="error"
                                disabled={
                                    submitting ||
                                    deleteEntityName !== entityName
                                }
                                onClick={deleteEntityCallback}
                            >
                                Delete {entityTypeDisplay}
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            )}
        </ContentModal>
    )
}

const EntityDeleteButtonContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '100%',
    width: '100%',
})

type EntityDeleteButtonProps = {
    entity?: { name?: string }
    id: string
    entityType: EntityType
    deleteEntity: (id: string, mutate: any) => Promise<any>
    caption: ReactNode
}

export function EntityDeleteButton({
    entity,
    id,
    entityType,
    deleteEntity,
    caption,
}: EntityDeleteButtonProps) {
    const [open, setOpen] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const internalUser = useUserIsInternal()

    const entityTypeDisplay = entityType === 'officer' ? 'Worker' : 'Customer'

    return (
        <EntityDeleteButtonContainer>
            {internalUser && (
                <>
                    <DeleteModal
                        open={open}
                        handleClose={handleClose}
                        entityName={entity?.name}
                        entityId={id}
                        entityType={entityType}
                        deleteEntity={deleteEntity}
                        caption={caption}
                    />
                    <Button
                        variant="text"
                        color="primary"
                        onClick={handleOpen}
                        fullWidth
                    >
                        Delete {entityTypeDisplay}
                    </Button>
                </>
            )}
        </EntityDeleteButtonContainer>
    )
}
