import { ContentModal } from '../Modal'
import { useState } from 'react'

import {
    Autocomplete,
    Button,
    Stack,
    TextField,
    ThemeProvider,
    Typography,
} from '@mui/material'
import newBelfryTheme from 'src/shared/styles/theme/newBelfryTheme'
import { startVisit, useVisitableFirms } from 'src/services/visitation'
import { useFirmVisitationState } from './firmVisitationState'

type FirmVisitationModalProps = {
    handleClose: () => void
}
export const FirmVisitationModal: React.FC<FirmVisitationModalProps> = ({
    handleClose,
}) => {
    const { visitable } = useVisitableFirms()
    const { setVisitId } = useFirmVisitationState()
    const [selectedFirmId, setSelectedFirmId] = useState<null | string>(null)
    const selectedFirm = visitable.find(({ id }) => id === selectedFirmId)
    const handleFirmSelected = async () => {
        if (!selectedFirmId) {
            return
        }
        const visit = await startVisit(selectedFirmId)
        setVisitId(visit.id)
        handleClose()
    }
    return (
        <ThemeProvider theme={newBelfryTheme}>
            <ContentModal isOpen={true} handleClose={() => handleClose()}>
                <Typography variant="h6">Visit another firm</Typography>
                <Stack direction="row">
                    <Autocomplete
                        sx={{ width: '100%' }}
                        getOptionLabel={({ name }) => name}
                        isOptionEqualToValue={({ id: idA }, { id: idB }) =>
                            idA === idB
                        }
                        value={selectedFirm}
                        options={visitable}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setSelectedFirmId(newValue.id)
                            }
                        }}
                    />
                    <Button
                        disabled={!selectedFirmId}
                        onClick={() => handleFirmSelected()}
                    >
                        Go
                    </Button>
                </Stack>
            </ContentModal>
        </ThemeProvider>
    )
}
