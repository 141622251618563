import React from 'react'
import { useRouter } from 'next/router'
import { validateCustomerId } from '../../services/customer'
import { InnerTabs } from '../InnerTabs/InnerTabs'
import { DetailsTab } from '../../types/detailsTab'

type LayoutProps = React.PropsWithChildren<{}>
export function TabbedCustomerBelltowerLayout({ children }: LayoutProps) {
    const router = useRouter()
    const id = validateCustomerId(router.query)
    const link = router.asPath

    const linksData: DetailsTab[] = [
        {
            name: 'Activity',
            link: `/customers/${id}/belltower/activity`,
            linkRoot: `/customers/${id}/belltower`,
        },
        {
            name: 'Reports',
            link: `/customers/${id}/belltower/reports`,
            linkRoot: `/customers/${id}/belltower`,
        },
        {
            name: 'Configuration',
            link: `/customers/${id}/belltower/configuration`,
            linkRoot: `/customers/${id}/belltower`,
        },
    ]
    return (
        <>
            <InnerTabs data={linksData} />
            {children}
        </>
    )
}
