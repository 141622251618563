import { Box, Button, MenuItem, Select, Typography } from '@mui/material'

import { FC, useState } from 'react'
import { toast } from 'react-toastify'

import { ContentModal } from 'src/components/Modal'
import { Officer } from 'src/generated'
import { patchOfficer } from 'src/services/officer'
import { useLoadOnboardingTemplates } from 'src/services/onboarding'
import { useSWRConfig } from 'swr'
import { officerCollisionToastError } from 'src/components/Officer/Contact/collisionToast'

type OnboardingChangeStatusModalProps = {
    open: boolean
    handleClose: () => void
    officer: Officer
}

const NONE_TEMPLATE_ID = 'none'

export const OnboardingChangeStatusModal: FC<
    OnboardingChangeStatusModalProps
> = ({ open, handleClose, officer }: OnboardingChangeStatusModalProps) => {
    const { mutate } = useSWRConfig()
    const [submitting, setSubmitting] = useState<boolean>(false)
    const [onboardingTemplateId, setOnboardingTemplateId] = useState<string>(
        officer.onboarding_template_id || NONE_TEMPLATE_ID
    )
    const { templates } = useLoadOnboardingTemplates()

    const onSubmit = async () => {
        setSubmitting(true)
        await patchOfficer({
            id: officer.id,
            officer: { onboarding_template_id: onboardingTemplateId },
            mutate,
            onPending: () => {
                toast.info('Saving...', { autoClose: false })
            },
            onError: (error) => {
                toast.dismiss()
                officerCollisionToastError(error)
            },
            onSuccess: () => {
                toast.dismiss()
                toast.success('Saved')
                handleClose()
            },
        })
        setSubmitting(false)
    }
    return (
        <ContentModal isOpen={open} handleClose={handleClose} showCloseButton>
            <Typography variant="h3" mb={1}>
                Enable Officer Onboarding
            </Typography>
            <Box flexBasis="35%">
                <Typography>Onboarding Template</Typography>
            </Box>
            <Box width="100%" padding="1em 0 1em 0">
                <Select
                    value={onboardingTemplateId}
                    onChange={(e) => setOnboardingTemplateId(e.target.value)}
                    size="small"
                    displayEmpty
                    fullWidth
                    defaultValue={NONE_TEMPLATE_ID}
                >
                    <MenuItem
                        defaultChecked
                        value={NONE_TEMPLATE_ID}
                        key={NONE_TEMPLATE_ID}
                    >
                        None
                    </MenuItem>
                    {templates?.map((template) => (
                        <MenuItem value={template.id} key={template.id}>
                            {template.name}
                        </MenuItem>
                    ))}
                </Select>
            </Box>

            <Button
                variant="contained"
                fullWidth
                disabled={
                    submitting ||
                    !onboardingTemplateId ||
                    onboardingTemplateId === NONE_TEMPLATE_ID
                }
                onClick={onSubmit}
            >
                Save and Trigger Onboarding
            </Button>
        </ContentModal>
    )
}
