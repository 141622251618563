import { Redo } from '@mui/icons-material'
import { Button, Tooltip, Typography } from '@mui/material'
import { type FC } from 'react'
import { type Firm } from 'src/generated'
import { useVisitableFirms } from 'src/services/visitation'
type FirmTitleProps = {
    firm: Pick<Firm, 'name'>
    onFirmSwitchClick: () => void
}

export const FirmTitle: FC<FirmTitleProps> = ({ firm, onFirmSwitchClick }) => {
    const { visitable } = useVisitableFirms()
    const canSwitch = !!visitable.length
    return (
        <>
            <Tooltip title={firm.name}>
                <Typography overflow="hidden" textOverflow="ellipsis">
                    {firm.name}
                </Typography>
            </Tooltip>
            {canSwitch && (
                <Tooltip title={'Switch firm'}>
                    <Button
                        onClick={() => onFirmSwitchClick()}
                        sx={{ color: 'white', ml: 'auto' }}
                    >
                        <Redo />
                    </Button>
                </Tooltip>
            )}
        </>
    )
}
