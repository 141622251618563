import React, { useState } from 'react'
import { Box } from '@mui/material'
import { CircularProgress } from '@mui/material'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { FileUpload } from '@uploadcare/react-widget'
import { updateOfficerImage } from '../../services/officer'
import { useSWRConfig } from 'swr'
import { UploadWrapper } from '../UploadWrapper'
import PlaceIcon from '@mui/icons-material/Place'
import EmailIcon from '@mui/icons-material/Email'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import { PostalAddress } from 'src/generated'
import {
    SignedUserImage,
    UnsignedUserImage,
} from '../OfficerLabel/OfficerLabel'
import { toast } from 'react-toastify'

interface EmployeeHeaderProps {
    userImageUrl?: string
    avatarSize?: number
    name: string
    children?: JSX.Element
    enableImageUpload?: boolean
    officerId?: string
    phoneNumber?: string
    email?: string
    address?: PostalAddress
    badges?: JSX.Element
}

type ProfileInfoWithAdornmentProps = {
    text: string
    icon: JSX.Element
}
const ProfileInfoWithAdornment = ({
    text,
    icon,
}: ProfileInfoWithAdornmentProps) => {
    return (
        <Box display="flex" gap={2}>
            {icon}
            <Typography variant="body2">{text}</Typography>
        </Box>
    )
}

export const EmployeeHeader = ({
    userImageUrl,
    avatarSize,
    name,
    children,
    enableImageUpload,
    officerId,
    phoneNumber,
    email,
    address,
    badges,
}: EmployeeHeaderProps) => {
    const [loading, setLoading] = useState(false)
    const { mutate } = useSWRConfig()
    const userImage = userImageUrl?.includes('token') ? (
        <SignedUserImage
            userImageUrl={userImageUrl}
            avatarSize={avatarSize}
            name={name}
        />
    ) : (
        <UnsignedUserImage
            userImageUrl={userImageUrl}
            avatarSize={avatarSize}
            name={name}
        />
    )

    const onFileSelect = async (file: FileUpload | null) => {
        if (file && officerId) {
            file.done(async (info) => {
                const fileInfo = await file.promise()
                setLoading(false)
                if (fileInfo.isImage && fileInfo.size) {
                    setLoading(true)
                    await updateOfficerImage(
                        officerId,
                        info.cdnUrl ?? undefined,
                        mutate
                    )
                } else {
                    toast.error('Please upload a valid image')
                }
            })
        }
    }

    return (
        <Grid container spacing={4} wrap="nowrap">
            <Grid item>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        alignItems: 'center',
                        justifyContent: 'left',
                        height: '100%',
                        cursor: 'pointer',
                    }}
                >
                    {loading ? (
                        <CircularProgress size={24} />
                    ) : (
                        <>
                            {enableImageUpload && (
                                <UploadWrapper onFileSelect={onFileSelect}>
                                    {userImage}
                                </UploadWrapper>
                            )}
                            {!enableImageUpload && userImage}
                        </>
                    )}
                </Box>
            </Grid>
            <Grid item sx={{ overflow: 'hidden' }}>
                <Box
                    display="flex"
                    flexDirection={'column'}
                    gap={'.6em'}
                    mt={'.2em'}
                >
                    <Box display="flex" gap={2}>
                        <Typography
                            variant="body1"
                            fontSize={24}
                            fontWeight={500}
                        >
                            {name}
                        </Typography>
                        {badges}
                    </Box>

                    {address ? (
                        <ProfileInfoWithAdornment
                            text={`${address?.city}, ${address?.state}`}
                            icon={
                                <PlaceIcon
                                    fontSize="small"
                                    sx={{ opacity: '50%' }}
                                />
                            }
                        />
                    ) : null}
                    {email ? (
                        <ProfileInfoWithAdornment
                            text={email}
                            icon={
                                <EmailIcon
                                    fontSize="small"
                                    sx={{ opacity: '50%' }}
                                />
                            }
                        />
                    ) : null}
                    {phoneNumber ? (
                        <ProfileInfoWithAdornment
                            text={phoneNumber}
                            icon={
                                <LocalPhoneIcon
                                    fontSize="small"
                                    sx={{ opacity: '50%' }}
                                />
                            }
                        />
                    ) : null}
                </Box>
            </Grid>
            {children}
        </Grid>
    )
}
