import { VisitationService } from 'src/generated'
import useSWRImmutable from 'swr/immutable'

export const useVisitableFirms = () => {
    const { data, error } = useSWRImmutable('/visitable_firms', () =>
        VisitationService.listVisitableFirmsApiV1FirmsVisitableGet()
    )

    return {
        visitable: data || [],
        isLoading: !error && !data,
        isError: !!error,
    }
}

export const startVisit = async (firmId: string) => {
    return await VisitationService.beginVisitApiV1BeginVisitPost({
        firm_id: firmId,
    })
}
