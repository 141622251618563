import React from 'react'
import { useRolesOfUser, useIsSubrole } from '../../utils/auth'

export {
    nonSupervisorRoles,
    payRateRoles,
    legalInfoRoles,
    billRateRoles,
} from '../../utils/auth'
// A higher order component, if the user has ANY of the allowedRoles, show the Component, otherwise show FallbackComponent if it exists
// referenceRoleId - only show this component if the users role is "above" this role.

/*eslint-disable react/display-name  */
export const withRole =
    (allowedRoles: string[]) =>
    (Component: any, FallbackComponent?: any) =>
    (props: any) => {
        const userRoles = useRolesOfUser()
        const maxRoleBlock = useIsSubrole(props.referenceroleid as string)
        if (
            userRoles &&
            allowedRoles.some((r) => userRoles.includes(r)) &&
            !maxRoleBlock
        ) {
            return <Component {...props} />
        } else if (FallbackComponent) {
            return <FallbackComponent {...props} />
        } else {
            return null
        }
    }
/*eslint-enable react/display-name  */
