import {
    Button,
    Typography,
    Box,
    ToggleButton,
    ToggleButtonGroup,
    Alert,
    AlertTitle,
} from '@mui/material'
import React from 'react'

import { updateOfficerCategory } from '../../../services/officer'
import { useSWRConfig } from 'swr'
import { Officer, OfficerCategory } from '../../../generated'
import { toast } from 'react-toastify'
import { ContentModal } from '../../Modal'

import { getOfficerCatLabel } from './PaySettings'

type WorkerCategoryChangeModalProps = {
    open: boolean
    handleClose: () => void
    officer: Officer
}

export const WorkerCategoryChangeModal = ({
    open,
    handleClose,
    officer,
}: WorkerCategoryChangeModalProps) => {
    const { mutate } = useSWRConfig()
    const [submitting, setSubmitting] = React.useState<boolean>(false)
    const [category, setCategory] = React.useState<OfficerCategory>(
        officer.category
    )

    const hasChanged = officer.category !== category
    const invalidBecauseSalaried =
        category == OfficerCategory.CONTRACTOR_1099_ && officer.salaried

    const maybeInvalidError = invalidBecauseSalaried ? (
        <Alert severity="error" sx={{ my: 1 }}>
            <AlertTitle>Contractors cannot be salaried</AlertTitle> Please
            switch this worker to an hourly pay basis first
        </Alert>
    ) : null
    const willChangeToExempt =
        category == OfficerCategory.CONTRACTOR_1099_ && !officer.exempt_employee
    const maybeChangeToExemptWarning = willChangeToExempt ? (
        <Alert severity="warning" sx={{ my: 1 }}>
            <AlertTitle>Will change classification to exempt</AlertTitle>{' '}
            Contractors must be exempt workers
        </Alert>
    ) : null

    const onSubmit = async () => {
        setSubmitting(true)
        toast.info('Changing worker category...', { autoClose: false })
        await updateOfficerCategory(officer.id, category, mutate)
        handleClose()
        toast.dismiss()
        toast.success('Worker category changed successfully')
        setSubmitting(false)
    }

    return (
        <ContentModal isOpen={open} handleClose={handleClose} showCloseButton>
            <Typography variant="h3" mb={1}>
                Change Worker Category
            </Typography>

            <Box>
                <Typography my={1}>
                    Currently {officer.name} is a &quot;
                    {getOfficerCatLabel(officer.category)}&quot;
                </Typography>
                <Typography>Change to:</Typography>
                <ToggleButtonGroup
                    color="primary"
                    value={category}
                    exclusive
                    onChange={(e, updatedCategory) => {
                        // don't allow toggling to cause none to be selected
                        if (updatedCategory) {
                            setCategory(updatedCategory)
                        }
                    }}
                >
                    {Object.values(OfficerCategory).map((officerCategory) => (
                        <ToggleButton
                            key={officerCategory}
                            value={officerCategory}
                        >
                            {getOfficerCatLabel(officerCategory)}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>

                {maybeInvalidError}

                {maybeChangeToExemptWarning}

                <Typography fontStyle="italic" fontSize="small" my={1}>
                    Changing worker category may have tax implications and may
                    require them to re-do payroll onboarding.
                </Typography>
                <Button
                    onClick={onSubmit}
                    variant="contained"
                    fullWidth
                    disabled={
                        !hasChanged || invalidBecauseSalaried || submitting
                    }
                >
                    Change category to &quot;{getOfficerCatLabel(category)}
                    &quot;
                </Button>
            </Box>
        </ContentModal>
    )
}
